.NewNote form textarea {
    height: 300px;
    font-size: 1.5rem;
  }

.hide {
    display: none;
}

.show {
    display: block;
}