@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
  }

  .format{
    background-color: #81b479 !important;
    border-color: #81b479 !important
  }

  .Login form a {
    margin-bottom: 15px;
    margin-top:10px;
    display: block;
    font-size: 14px;
  }