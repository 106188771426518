.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.listwrap {
  overflow-wrap: break-word;
}

.search-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.search-form{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
}

.form-style{
  width: 90%;
  margin-right: 2rem;
}