.App {
  margin-bottom: 45%;
}

.color-nav {
  background-color: rgba(129,180,121,255);
  padding-right: 1rem;
}
.font-color {
  color: rgb(255, 255, 255) !important
}
.navbar-brand > img {
  object-fit: cover;
}

.logoImage{
  object-fit: cover;
  width: 100px;
  height: 50px;
}

.navItems{
  margin-left: 10px;
}

.homeImage{
  object-fit: contain;
  width: 30%;
  float: right;
  padding: 1%;
  margin-left: 10%;
}