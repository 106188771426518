.rounded {
    position: relative;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.scanner {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.scannerC {
    margin-left: 0 !important;
}

.scannerC .row {
    border-bottom: 0.5px solid #ccc;
    padding: 5px;
    align-items: center;
}

.floatingQR {
    bottom: 0;
    right: 5%;
    padding: 10px;
    margin: 5px;
}

.floatingQRContainer {
    position: fixed;
    bottom: 12%;
    right: 5%;
    background: rgba(129, 180, 121, 0.5);
    border-radius: 50%;
}

.trashCls {
    border: none;
    background: transparent;
    outline: none;
}

.imgData img{
    display: block;
    margin: auto;
}

.detailsAlign{
    display: flex;
    justify-content: space-between;
}