.NewNote form textarea {
    height: 300px;
    font-size: 1.5rem;
  }

.hide {
    display: none;
}

.show {
    display: block;
}

.scanner {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.scannerC {
    margin-left: 0 !important;
}

.doNotUseError {
    border: 5px solid red;
    border-radius: 5px;
    color: red;
}

.fieldSetCustom{
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 2px;
    border-style: groove;
    border-color: rgb(192, 192, 192);
    border-image: initial;
    margin-bottom: 5px;
}

legend{
float: none;
width: auto;
padding: 0;
margin-bottom: 0.5rem;
font-size: calc(1.275rem + .3vw);
line-height: inherit;
}

label{
font-size: 0.8rem;
}