.NewNote form textarea {
    height: 300px;
    font-size: 1.5rem;
  }

.hide {
    display: none;
}

.show {
    display: block;
}

.scanner {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.scannerC {
    margin-left: 0 !important;
}

.accordion-button{
    word-break: break-all;
}

.word-break{
    word-break: break-all;
}