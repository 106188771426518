.Dashboard .NavButtons a {
    color: black;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
}

.NavButtons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 0.5rem;
}